import React from 'react';
import { getDomains, postRun, postChunks } from '../Api';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Button, Box, Spacer , Input, Container, Center, HStack,
    Tabs, TabList, TabPanels, Tab, TabPanel, Spinner, Stack, Card, CardBody, CardHeader, Tooltip,
    Heading, Text, useToast, IconButton, Menu, MenuButton,  MenuList, MenuItem, 
    VStack, 
    Icon} from '@chakra-ui/react';
import { SearchIcon, ChevronDownIcon, ArrowRightIcon, RepeatIcon } from '@chakra-ui/icons'

import LoadingOverlay from '../components/uiLockingComponent';

const ChatbotPage = ({isSignedIn}) => {

    const toast = useToast();
    const navigate = useNavigate();

    const [allDomainsData, setAllDomainsData] = useState({ result: [] });
    const [activedDomainIndex, setActivedDomainIndex] = useState(1);

    const [inputQuery, setInputQuery] = useState("");

    const [queryResponses, setQueryResponses] = useState({});
    const [chunkResponses, setChunkResponses] = useState({});

    const [isQueryResponseShowed, setIsQueryResponseShowed] = useState(false);
    const [isChunkResponseShowed, setIsChunkResponseShowed] = useState(false);

    const [isUILocked, setUILocked] = useState(false);

    const [whichAssistant, setWhichAssistant] = useState("langchain");

    console.log(whichAssistant);

    useEffect(() => {
        (async () => {
            try {
                const data = await getDomains();
                setAllDomainsData(data);
                setActivedDomainIndex(data.result[0]?.domain_id);
                setInputQuery(data.result.find(domain => domain.domain_id === activedDomainIndex)?.default_prompt_text || "데이터 로딩 중...")
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '도메인 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        })();
    }, []);

    const handlePostRun = useCallback(async () => {

        if (inputQuery === "") {
            toast({
                title: 'Failed',
                description: '질문을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
        else {
            setUILocked(true);
            setIsQueryResponseShowed(false);
            setIsChunkResponseShowed(false);
            try {
                const data = await postRun(inputQuery, whichAssistant);
                setQueryResponses(data);
                setIsQueryResponseShowed(true);
                setUILocked(false);
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            } finally {
                setUILocked(false);
                setIsQueryResponseShowed(true);
            }
        }
    }, [inputQuery, whichAssistant]);


    useEffect(() => {
        if (queryResponses) {
            (async () => {
                try {
                    const data = await postChunks(queryResponses);
                    setChunkResponses(data.results);
                } catch (error) {
                    toast({
                        title: 'Failed',
                        description: '답변 처리 실패',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                }
            })();
        }
    }, [queryResponses]);

    useEffect(() => {
        if (chunkResponses && Object.keys(chunkResponses).length > 0) {
            setIsChunkResponseShowed(true);
        }
    }, [chunkResponses]); 

    useEffect(()=>{
        setIsQueryResponseShowed(false);
        setIsChunkResponseShowed(false);
    }, [activedDomainIndex])
  
    return(
        <Container maxW = "container.xl" p ='4'>
            <LoadingOverlay isLoading={isUILocked} />
            <Flex gap='10' flexDirection="column">
                <Container maxW = "container.xl" p='4' pt= '20' >
                    <Flex>
                        <Box 
                            size='lg' 
                            bg='teal.500' 
                            p='3' 
                            fontWeight='semibold' 
                            color='white' 
                            borderRadius='5'
                            shadow='md'
                            borderWidth='1px'
                        >
                            <h1> RAG 검색 </h1>
                        </Box>
                        <Menu >
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size='lg' 
                            bg='gray' 
                            p='3' 
                            fontWeight='semibold' 
                            color='white' 
                            borderRadius='5'
                            shadow='md'
                            borderWidth='1px'>
                            {whichAssistant}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={(e)=>{
                                setWhichAssistant(e.target.textContent);
                            }}>langchain</MenuItem>
                            <MenuItem onClick={(e)=>{
                                setWhichAssistant(e.target.textContent);
                            }}>langgraph</MenuItem>
                            <MenuItem onClick={(e)=>{
                                setWhichAssistant(e.target.textContent);
                            }}>raptor</MenuItem>
                        </MenuList>
                        </Menu>

                        <Spacer />
                        <IconButton
                                borderWidth='1px' shadow='md' size = 'lg'
                                onClick={() => {
                                    if (isSignedIn === false) {
                                        navigate('/signin-page');
                                    } else {
                                        navigate('/rag-page');
                                    }
                                }}
                                icon={<ArrowRightIcon />}
                                colorScheme='teal'
                            />
                    </Flex>
                </Container>

                <Container maxW = "container.xl" p= "4" h = "100vh" >
                    <HStack justifyContent="space-between" w="full">
                        <HStack>
                            <Menu>
                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme='teal' w="300" shadow='md' borderWidth='1px'>
                                    {allDomainsData.result[activedDomainIndex-1]?.domain_name || "데이터 로딩 중..."}
                                </MenuButton>
                                <MenuList>
                                    {allDomainsData.result.map((domainData)=>(
                                        <MenuItem
                                            key = {domainData.domain_id}
                                            onClick={()=>{
                                                setActivedDomainIndex(domainData.domain_id)
                                                setInputQuery(domainData.default_prompt_text)
                                            }}
                                            >
                                            {domainData.domain_name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                            <Input
                                w='700px'
                                borderWidth='1px' shadow='md'
                                value={inputQuery}
                                readOnly={isUILocked}
                                placeholder={"어떤 질문을 하고 싶으신가요? ex) 사내 규칙을 알려줘." || "데이터 로딩 중..."}
                                onChange={(e) => {setInputQuery(e.target.value);}}
                                onKeyDown={(e) => {
                                    if(e.key === 'Enter') 

                                    handlePostRun();

                                }}
                            />
                            <IconButton
                                borderWidth='1px' shadow='md'
                                onClick={() => {

                                    handlePostRun();

                                }}
                                icon={<SearchIcon />}
                                colorScheme='blue'
                            />
                        </HStack>
                        <HStack>
                        <IconButton
                            icon={<RepeatIcon />}
                            onClick={()=>{
                                setIsQueryResponseShowed(false);
                                setIsChunkResponseShowed(false);
                                setActivedDomainIndex(1);
                                setInputQuery(allDomainsData.result[0]?.default_prompt_text || "데이터 로딩 중...")
                            }}
                            shadow='md'
                            borderWidth='1px'
                        />
                        {/* <Center bg='gray.300' color='black' fontWeight='bold' m='3' >
                                {allDomainsData.result[activedDomainIndex-1]?.domain_desc || "데이터 로딩 중..."}
                        </Center> */}
                        </HStack>
                    </HStack>
                    {isQueryResponseShowed && (
                    <Tabs size='md' variant='enclosed' mt='10' mb='50'>
                    <TabList>
                        <Tab  _selected={{ color: 'white', bg: 'teal.500', fontWeight: 'bold', width : "25%"}} borderWidth='1px'>AI 답변</Tab>
                    </TabList>
                    <TabPanels shadow='md' borderWidth='1px'>
                        <TabPanel>
                        <Box style={{ whiteSpace: "pre-wrap" }} >
                            {queryResponses.query_response || "데이터 로딩 중..."}
                        </Box>
                        </TabPanel>
                    </TabPanels>
                    </Tabs>
                    )}
                    {isChunkResponseShowed && (
                        <Stack spacing='4'>
                        {chunkResponses.map((chunkResponse) => (
                            <Card 
                            key={`${chunkResponse.file_ids}-${chunkResponse.chunk_ids}`}
                            size='lg'
                            shadow='md' >
                            <CardHeader>
                                <Heading size='md'>{chunkResponse.title}</Heading>
                            </CardHeader>
                            <CardBody style={{ whiteSpace: "pre-wrap" }}>
                                <Text>{chunkResponse.Content}</Text>
                            </CardBody>
                          </Card>
                        ))}
                      </Stack>
                    )}
                </Container>
            </Flex>
        </Container>
    )


}

export default ChatbotPage;