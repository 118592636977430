import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const LoadingOverlay = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <Flex
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            zIndex="9999"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner                             
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='teal.500'
                size='xl' 
            />
        </Flex>
    );
};

export default LoadingOverlay; 
