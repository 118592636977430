import config from './config.json';

const getTokens = (type) => {
    if (type === 'refresh') {
        const refreshToken = localStorage.getItem('refresh-token');
        return {'refresh-token': refreshToken};
    } else if (type === 'access') {
        const accessToken = localStorage.getItem('access-token');
        return {'access-token': accessToken};
    }
}

const getDomains = async () => {
    const apiUrl = `${config.config.base_url}/api/domains`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const postRun = async (inputQuery, whichAssistant) => {
    const apiUrl = `${config.config.base_url}/api/run/${whichAssistant}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: inputQuery,
            }),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const postChunks = async (queryResponses) => {
    const apiUrl = `${config.config.base_url}/api/get_chunks`;

    const chunkIds = queryResponses.chunk_ids || [];
    const fileIds = queryResponses.file_ids || [];

    let chunkRequestData = chunkIds.map((chunk_id, index) => {
        return {
            file_ids: fileIds[index],
            chunk_ids: chunk_id
        };
    });

    chunkRequestData = {"query": chunkRequestData};

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(chunkRequestData),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};


const getRags = async (activedDomainIndex, inputKeyword) => {
    const accessToken = getTokens("access");

    const apiUrl = `${config.config.base_url}/api/rag?domain_id=${activedDomainIndex}&keyword=${encodeURIComponent(inputKeyword)}`;

    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const updateRagOneRow = async (drawerValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${config.config.base_url}/api/rag`;

    try {
        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            body: JSON.stringify({
                "file_id": drawerValue.file_id,
                "file_name": drawerValue.file_name,
            }),
        });

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const deleteRagOneRow = async (drawerValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${config.config.base_url}/api/rag`;

    try {
        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            body: JSON.stringify({
                "file_id": drawerValue.file_id
            }),
        });

        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
};

const createRagOneRow = async (modalValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${config.config.base_url}/api/rag`;

    const formData = new FormData();
    formData.append('domain_id', modalValue.domainId);
    formData.append('file_name', modalValue.fileName);
    formData.append('domain_name', modalValue.domainName);
    formData.append('file', modalValue.file);

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const postSignIn = async (username, password) => {
    const apiUrl = `${config.config.base_url}/api/auth/sign_in`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                client_id: username,
                client_password: password
            }),
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));
        
        return data;
    } catch (error) {
        throw error;
    }
};

const getRefresh = async () => {
    const refreshToken = getTokens("refresh");

    const apiUrl = `${config.config.base_url}/api/auth/refresh`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken['refresh-token']}`
            },
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));

        return data;
    } catch (error) {
        throw error;
    }
};

export {
    getDomains,
    postRun,
    postChunks,
    getRags,
    updateRagOneRow,
    deleteRagOneRow,
    createRagOneRow,
    postSignIn,
    getRefresh,
    
};
