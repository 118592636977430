import React, { useState, useEffect, useCallback } from 'react';
import { ChakraProvider, useToast } from '@chakra-ui/react'
import { Routes, Route, BrowserRouter, useNavigate } from 'react-router-dom';

import ChatbotPage from './pages/ChatbotPage';
import RagPage from './pages/RagPage';
import SignInPage from './pages/SignInPage';
import { calculateSessionExpireTime, unixToReadableTime } from './components/timeCalculatingComponent';
import {getRefresh} from './Api';


function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [sessionExpireTime, setSessionExpireTime] = useState(0);
  const [accessTokenExpireTime, setAccessTokenExpireTime] = useState(0); // UNIX 타임스탬프로
  const [refreshTokenExpireTime, setRefreshTokenExpireTime] = useState(0); // UNIX 타임스탬프로
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [apiCalled, setApiCalled] = useState(0);

  const navigate = useNavigate();
  const toast = useToast();


  useEffect(() => {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if(accessToken && refreshToken) {
        setIsSignedIn(true);
    } else {
        setIsSignedIn(false);
    }
  }, []);

  const handleGetRefresh = useCallback(async () => {
    try {
        const data = await getRefresh();
        
        if (data.result === 1) {
            toast({
                title: '토큰이 자동 재발급 되었습니다.',
                description: '토큰이 자동 재발급 되었습니다.',
                status: 'info',
                isClosable: true,
                position: 'top'
            });
            return true;
        }

        toast({
            title: 'Fetch Failed',
            description: '토큰 자동 갱신 실패. 재로그인 하세요.',
            status: 'error',
            isClosable: true,
            position: 'top'
        });
    } catch (error) {
        toast({
            title: 'Fetch Failed',
            description: '토큰 자동 갱신 실패. 재로그인 하세요.',
            status: 'error',
            isClosable: true,
            position: 'top'
        });
    } finally {
        setApiCalled(prev => prev + 1);
    }
    return false;
  }, []);

  const checkExpireTime = useCallback(async () => {
    const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
    const accessTokenExpireTime = parseInt(localStorage.getItem('access-token-exp'));
    const refreshTokenExpireTime = parseInt(localStorage.getItem('refresh-token-exp'));
    
    console.log("----------------클라이언트 이용 기록 시작----------------");
    console.log("현재 시간 : ", unixToReadableTime(Date.now()));
    console.log("세션 만료 시간: ", unixToReadableTime(sessionExpireTime));
    console.log("엑세스 토큰 만료 시간: ", unixToReadableTime(accessTokenExpireTime));
    console.log("갱신 토큰 만료 시간: ", unixToReadableTime(refreshTokenExpireTime));
    console.log("엑세스 토큰: ", localStorage.getItem('access-token'));
    console.log("리프레시 토큰: ", localStorage.getItem('refresh-token'));

    if (sessionExpireTime <= Date.now() && sessionExpireTime !== null) {     
        console.log("1. 세션 만료됨");
        setIsSessionExpired(true);
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('session-exp');
        localStorage.removeItem('access-token-exp');
        localStorage.removeItem('refresh-token-exp');
        navigate('/signin-page');
        return false;
    } else if (sessionExpireTime > Date.now() && sessionExpireTime !== null) {
        console.log("2. 세션 시간 갱신됨");
        localStorage.setItem('session-exp', calculateSessionExpireTime());
    }

    if (sessionExpireTime > Date.now() && accessTokenExpireTime <= Date.now() && refreshTokenExpireTime > Date.now()) {
        console.log("엑세스 토큰 만료됨");
        console.log("리프레시 토큰 갱신 시도");

        const refreshResult = await handleGetRefresh();
        return refreshResult;
    }
    console.log("----------------클라이언트 이용 기록 끝----------------");

    return true;
  }, [handleGetRefresh, navigate, setIsSessionExpired]);

  return (
    <Routes>
      <Route 
        path="/" 
        element={<ChatbotPage isSignedIn={isSignedIn}/>}/>
      <Route 
        path="/rag-page" 
        element={<RagPage 
        isSignedIn={isSignedIn} 
        checkExpireTime={checkExpireTime}
        />} />
      <Route 
        path="/signin-page" 
        element={<SignInPage 
        isSignedIn={isSignedIn} 
        setIsSignedIn={setIsSignedIn}
        
        // 세션 관련
        sessionExpireTime={sessionExpireTime}
        accessTokenExpireTime={accessTokenExpireTime}
        refreshTokenExpireTime={refreshTokenExpireTime}
        setSessionExpireTime={setSessionExpireTime}
        setAccessTokenExpireTime={setAccessTokenExpireTime}
        setRefreshTokenExpireTime={setRefreshTokenExpireTime}
        
        />}/>
    </Routes>
  );
}

function AppWrapper() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default AppWrapper;
