import React, { useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { HStack, Button, IconButton } from "@chakra-ui/react";

const PaginationComponent = ({ allData, currentPage, setCurrentPage, recordsPerPage }) => {
    
    const allDataLength = (allData || []).length;
    const totalPages = Math.ceil(allDataLength / recordsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };


    const getPageButtons = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <Button key={i} onClick={() => handlePageChange(i)} colorScheme={currentPage === i ? "teal" : "gray"}>
                    {i}
                </Button>
            );
        }
        return pageNumbers;
    };

    return (
        <HStack gap='2'>
            <IconButton 
                icon={<ArrowLeftIcon />}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} 
            />
            {getPageButtons()}
            <IconButton 
                icon={<ArrowRightIcon />}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}  
            />
        </HStack>
    );
}

export default PaginationComponent;
