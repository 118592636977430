import React from "react";
import { getDomains, getRags, 
    // getRagsFiltered, 
    updateRagOneRow, deleteRagOneRow, createRagOneRow } from '../Api';
import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import PaginationComponent from "../components/paginationComponent";

import { Container, Flex, Spacer, IconButton, Box, TableContainer, 
    Table, TableCaption, Thead, Tr, Th, Tbody, Td, Menu, MenuButton, Button, 
    MenuList, MenuItem, Input, HStack, useDisclosure, Modal, ModalOverlay,ModalContent,ModalHeader,
    ModalCloseButton, ModalBody, Text, ModalFooter, Drawer, DrawerBody, DrawerFooter, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, Stack, FormLabel, FormControl, Textarea, Badge,
    Select, useToast, Center } from "@chakra-ui/react";
import { SearchIcon, ArrowLeftIcon, ChevronDownIcon, RepeatIcon } from "@chakra-ui/icons";

import LoadingOverlay from '../components/uiLockingComponent';


const RagPage = ({isSingedIn, checkExpireTime }) => {

    const toast = useToast();
    const navigate = useNavigate();
    const allowedTypes = [
        'application/pdf', // pdf
        'text/plain', // txt
        'application/x-hwp', // hwp
        'application/haansofthwp', // hwp2
        'application/vnd.hancom.hwp', // hwp3
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', //pptx
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'text/csv', //csv
    ];
    const fileTypeCheckList = [
        "pdf", "txt", "hwp", "xlsx", "docx", "pptx", "csv"
    ];
    const recordsPerPage = 30;

    const [allRagsData, setAllRagsData] = useState({ result : [] });
    const [allDomainsData, setAllDomainsData] = useState({ result: [] });
    const [activedDomainIndex, setActivedDomainIndex] = useState(0);

    const [inputKeyword, setInputKeyword] = useState("");

    // Drawer 관련 상태 시작
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const [drawerValue, setDrawerValue] = useState({});
    // Drawer 관련 상태 끝

    // Modal 관련 상태 시작
    const initialRef = useRef(null);
    const fileInputRef = useRef(null);
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const [modalValue, setModalValue] = useState({domainId: '1', domainName: '복리후생', fileName: '', file: {name: "파일 선택"}}); // default modal value 상수로 처리하기

    const modalFileInputButtonClick = () => {
      fileInputRef.current.click();

    };
    // Modal 관련 상태 끝

    const [isUILocked, setUILocked] = useState(false);

    // pagination
    const [currentRagPage, setCurrentRagPage] = useState(1);
    const [currentRagData, setCurrentRagData] = useState([]);

    useEffect(() => {
        const checkAndFetchDomains = async () => {
            checkExpireTime();
    
            const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
            // check if the session is not expired before fetching domains
            if (sessionExpireTime > Date.now()) {
                try {
                    const data = await getDomains();
                    setAllDomainsData(data);
                } catch (error) {
                    toast({
                        title: 'Failed',
                        description: '도메인 정보 가져오기 실패',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                }
            } else {
                console.log('Session is expired, not fetching domains');
            }
        };
    
        checkAndFetchDomains();
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            checkExpireTime();
            
            const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
            if (sessionExpireTime > Date.now()) {
                try {
                    const data = await getRags(activedDomainIndex, inputKeyword);
                    if (Array.isArray(data.result)) {
                        setAllRagsData(data);
                        return;
                    }
                    if (data.result === 'Token Expired') {
                        toast({
                            title: '토큰이 만료되었습니다.',
                            description: '토큰이 만료되었습니다. 재발급 받으세요',
                            status: 'info',
                            isClosable: true,
                            position: 'top'
                        });
                        return;
                    }
                    toast({
                        title: 'Fetch Failed',
                        description: 'RAG 정보 가져오기 실패',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                } catch (error) {
                    toast({
                        title: 'Failed',
                        description: 'RAG 정보 가져오기 실패',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                }
            } else {
                navigate('/signin-page');
            }
        };
    
        fetchData();
    }, [activedDomainIndex]);
    
    const handleGetRags = useCallback(async () => {
        checkExpireTime();
        
        const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
        if (sessionExpireTime > Date.now()) {
            try {
                const data = await getRags(activedDomainIndex, inputKeyword);
                
                if (Array.isArray(data.result)) {
                    setAllRagsData(data);
                    return;
                }
        
                if (data.result === 'Token Expired') {
                    toast({
                        title: '토큰이 만료되었습니다.',
                        description: '토큰이 만료되었습니다. 재발급 받으세요',
                        status: 'info',
                        isClosable: true,
                        position: 'top'
                    });
                    return;
                }
        
                toast({
                    title: 'Fetch Failed',
                    description: 'RAG 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            } catch (error) {
                toast({
                    title: 'Fetch Failed',
                    description: 'RAG 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        } else {
            navigate('/signin-page');
        }
    }, [activedDomainIndex, inputKeyword]);
    
    const handleUpdateRagOneRow = useCallback(async () => {
        checkExpireTime();
        
        const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
        if (sessionExpireTime > Date.now()) {
            try {
                const data = await updateRagOneRow(drawerValue);
    
                if (data.result === 'Token Expired') {
                    toast({
                        title: '토큰이 만료되었습니다.',
                        description: '토큰이 만료되었습니다. 재발급 받으세요',
                        status: 'info',
                        isClosable: true,
                        position: 'top'
                    });
                    return;
                }
    
                if (data.result === 1) {
                    onDrawerClose();
                    toast({
                        title: 'Update Successful',
                        description: 'RAG data가 업데이트 되었습니다.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Something Wrong',
                        description: data.result,
                        status: 'error',
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: 'Update Failed',
                    description: '업데이트 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            } finally {
                const data = await getRags(0, "");
                setAllRagsData(data);
            }
        } else {
            navigate('/signin-page');
        }
    }, [drawerValue]);
    
    const handleDeleteRagOneRow = useCallback(async () => {
        checkExpireTime();
        
        const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
        if (sessionExpireTime > Date.now()) {
            if (drawerValue.status === "uploading") {
                toast({
                    title: 'Delete Failed',
                    description: '업로드 중인 파일은 삭제할 수 없습니다.',
                    status: 'error',
                    isClosable: true,
                });
                return;
            } else if (drawerValue.status === "uploaded") {
                setUILocked(true);
                try {
                    const data = await deleteRagOneRow(drawerValue);
    
                    if (data.result === 'Token Expired') {
                        toast({
                            title: '토큰이 만료되었습니다.',
                            description: '토큰이 만료되었습니다. 재발급 받으세요',
                            status: 'info',
                            isClosable: true,
                            position: 'top'
                        });
                        return;
                    }
    
                    const ragsData = await getRags(0, "");
                    setAllRagsData(ragsData);
        
                    if (data.result === 1) {
                        setUILocked(false);
                        onDrawerClose();
                        toast({
                            title: 'Delete Successful',
                            description: 'RAG data가 삭제 되었습니다.',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: 'Delete Failed',
                            description: "삭제 실패. 관리자에게 문의하세요",
                            status: 'error',
                            isClosable: true,
                        });
                    }
                } catch (error) {
                    toast({
                        title: 'Delete Failed',
                        description: '삭제 실패. 관리자에게 문의하세요.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                } finally {
                    const data = await getRags(0, "");
                    setAllRagsData(data);
                    setUILocked(false);
                }
            } else {
                toast({
                    title: 'Delete Failed',
                    description: '삭제할 수 없는 파일입니다.',
                    status: 'error',
                    isClosable: true,
                });
                return;
            }
        } else {
            navigate('/signin-page');
        }
    }, [drawerValue]);
    
    const handleCreateRagOneRow = useCallback(async () => {
        checkExpireTime();
        
        const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
        if (sessionExpireTime > Date.now()) {
            if (!modalValue.file) {
                toast({
                    title: '파일 없음',
                    description: '파일을 첨부해 주세요.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            } else if (modalValue.file.size > 10485760) {
                toast({
                    title: '파일 크기 초과',
                    description: '10MB 이하의 파일만 첨부 가능합니다.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            } else {
                if (modalValue.file.name.includes('hwp')) {
                    setUILocked(true);
                    try {
                        const data = await createRagOneRow(modalValue);  
    
                        if (data.result === 'Token Expired') {
                            toast({
                                title: '토큰이 만료되었습니다.',
                                description: '토큰이 만료되었습니다. 재발급 받으세요',
                                status: 'info',
                                isClosable: true,
                                position: 'top'
                            });
                            return;
                        }
    
                        if (data.result === 1) {
                            onModalClose();
                            setUILocked(false);
                            toast({
                                title: 'Create Successful',
                                description: 'RAG data가 추가 되었습니다.',
                                status: 'success',
                                duration: 5000,
                                isClosable: true,
                            });
                        } else {
                            toast({
                                title: 'Something Wrong',
                                description: data.result,
                                status: 'error',
                                isClosable: true,
                            });
                        }
                    } catch (error) {
                        toast({
                            title: 'Create Failed',
                            description: '추가 실패: 관리자에게 문의하세요.',
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        });
                    } finally {
                        setUILocked(false);
                        const data = await getRags(0, "");
                        setAllRagsData(data);
                        setModalValue({domainId: '1', domainName: '복리후생', fileName: '', file: {name: "파일 선택"}});
                    }
                } else {
                    if (!allowedTypes.includes(modalValue.file.type)) {
                        toast({
                            title: '파일 형식 오류',
                            description: `파일 형식은 ${fileTypeCheckList}만 가능합니다.`,
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        });
                        return;
                    } else {
                        setUILocked(true);
                        try {
                            const data = await createRagOneRow(modalValue); 
                            
                            if (data.result === 'Token Expired') {
                                toast({
                                    title: '토큰이 만료되었습니다.',
                                    description: '토큰이 만료되었습니다. 재발급 받으세요',
                                    status: 'info',
                                    isClosable: true,
                                    position: 'top'
                                });
                                return;
                            }
    
                            if (data.result === 1) {
                                onModalClose();
                                setUILocked(false);
                                toast({
                                    title: 'Create Successful',
                                    description: 'RAG data가 추가 되었습니다.',
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                });
                            } else {
                                toast({
                                    title: 'Something Wrong',
                                    description: data.result,
                                    status: 'error',
                                    isClosable: true,
                                });
                            }
                        } catch (error) {
                            toast({
                                title: 'Create Failed',
                                description: '추가 실패: 관리자에게 문의하세요.',
                                status: 'error',
                                duration: 5000,
                                isClosable: true,
                            }); 
                        } finally {
                            setUILocked(false);
                            const data = await getRags(0, "");
                            setAllRagsData(data);
                            setModalValue({domainId: '1', domainName: '복리후생', fileName: '', file: {name: "파일 선택"}});
                        }
                    }
                }
            }
        } else {
            navigate('/signin-page');
        }
    }, [modalValue]);
    

    useEffect(() => {
        if (allRagsData && allRagsData.result) {
            setCurrentRagData(allRagsData.result.slice((currentRagPage*recordsPerPage)-recordsPerPage, currentRagPage*recordsPerPage))
        } else {
            setCurrentRagData([]);
        }
    },[currentRagPage, allRagsData])


    const statusBadge = (status) => {
        if (status === "uploading") {
            return(
                <Badge colorScheme="orange">업로드 중</Badge>
            )
        } else if (status === "uploaded") {
            return(
                <Badge colorScheme='green'>업로드 성공</Badge>
            )
        } else if (status === "deleting") {
            return(
                <Badge colorScheme='red'>삭제중</Badge>
            )
        } else if (status === "deleted") {
            return(
                <Badge colorScheme='tomato'>삭제됨</Badge>
            )
        }
    }

    const uploadedTime = (time) => {

        const yearMonthDay = time.split('T')[0];
        const hourMinuteSeconds = time.split('T')[1].split('.')[0];

        return yearMonthDay + " " + hourMinuteSeconds;
    }

    // console.log("drawerValue : ", drawerValue);
    // console.log("현재 활성화된 도메인 인덱스 : ", activedDomainIndex);
    // console.log("allragsdata : ", allRagsData);
    // console.log("inputKeyword : ", inputKeyword);
    // console.log("modalValue : ", modalValue);
    // console.log(currentRagPage);
    // console.log(currentRagData);

    return(
        <Container maxW="container.xl" p='4' >
            <LoadingOverlay isLoading={isUILocked} />
            <Flex gap='10' flexDirection="column">
                <Container maxW="container.xl" p='4' pt='20'>
                    <Flex>
                        <Box size='lg' bg='teal.500' p='3' fontWeight='semibold' color='white' borderRadius='5'>
                            <h1> RAG 목록 </h1>
                        </Box>
                        <Button 
                        bg='gray' 
                        color='white' 
                        size='lg' 
                        ml='3'
                        onClick={()=>{
                            localStorage.removeItem('access-token');
                            localStorage.removeItem('refresh-token');
                            localStorage.removeItem('session-exp');
                            localStorage.removeItem('access-token-exp');
                            localStorage.removeItem('refresh-token-exp');
                            navigate('/signin-page');
                        
                        }}
                        >Sign out</Button>
                        <Spacer />
                        <IconButton
                            borderWidth='1px' shadow='md' size = 'lg'
                            onClick={() => { 
                                navigate('/');
                            }}
                            icon={<ArrowLeftIcon />}
                            colorScheme='teal'
                        />
                    </Flex>
                </Container>
                <Container maxW="container.xl" p='4' >
                    <Flex gap='5' direction='column'>
                    <Box fontWeight='semibold' color='white' borderRadius='5'>
                        <HStack spacing='15px' justifyContent="space-between" w="full">
                            <HStack>
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="teal" w="300" shadow='md' borderWidth='1px'>
                                        {allDomainsData.result[activedDomainIndex-1]?.domain_name || "도메인 전체"}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem key={0} onClick={() => {
                                            setActivedDomainIndex(0);
                                            setCurrentRagPage(1);
                                        }} color='black'>
                                            도메인 전체
                                        </MenuItem>
                                        {allDomainsData.result.map((domainData) => (
                                            <MenuItem key={domainData.domain_id} onClick={() => {
                                                setActivedDomainIndex(domainData.domain_id);
                                                setCurrentRagPage(1);
                                            }} color='black'>
                                                {domainData.domain_name}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </Menu>
                                <Input 
                                    w="600"
                                    onChange={(e) => setInputKeyword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if(e.key === 'Enter') 
                                        handleGetRags();
                                        
                                    }}
                                    color='black'
                                    value={inputKeyword}
                                />
                                <IconButton
                                    borderWidth='1px' shadow='md'
                                    onClick={() => { handleGetRags(); }}
                                    icon={<SearchIcon />}
                                    colorScheme='blue'
                                />
                            </HStack>

                            <HStack>
                                <IconButton 
                                    icon={<RepeatIcon />}
                                    onClick={() => {
                                        try {
                                            setActivedDomainIndex(0);
                                            setInputKeyword("");
                                        } finally {
                                            handleGetRags();
                                        }
                                    }}
                                />
                                <Button 
                                    colorScheme='blue'
                                    onClick={() => {
                                        onModalOpen();
                                    }}
                                >
                                    RAG 추가
                                </Button>
                            </HStack>
                        </HStack>
                    </Box>
                    
                    <TableContainer>
                    <Table variant='simple'>
                        {/* <TableCaption>2024 © Didim365. All Rights Reserved.</TableCaption> */}
                        <Thead>
                            <Tr>
                                <Th isNumeric>No.</Th>
                                <Th>도메인</Th>
                                <Th>이름</Th>
                                <Th>문서 상태</Th>
                                <Th>등록자</Th>
                                <Th>등록일자</Th>
                            </Tr>
                        </Thead>
                        {currentRagData.map((ragData, index)=>(
                        <Tbody
                            key={index}
                            _hover={{ bg: 'gray.100', cursor: 'pointer'}}
                            onClick={()=>{
                                onDrawerOpen();
                                setDrawerValue({
                                    "id": index,
                                    "file_id": ragData.file_id,
                                    "domain_name": ragData.domain_name, 
                                    "file_name": ragData.file_name, 
                                    "status": ragData.status, 
                                    "default_prompt_text": ragData.default_prompt_text, 
                                    "name": ragData.name, 
                                    "edited_date": ragData.edited_date});
                                }}>
                            <Tr>
                                <Td>{index+1}</Td>
                                <Td>{ragData.domain_name}</Td>
                                <Td >{ragData.file_name}</Td>
                                <Td >{statusBadge(ragData.status)}</Td>
                                <Td >{ragData.name}</Td>
                                <Td >{uploadedTime(ragData.edited_date)}</Td>
                            </Tr>
                        </Tbody>
                        ))}
                    </Table>
                    </TableContainer>
                    <Center>
                    <PaginationComponent 
                        allData={allRagsData.result} 
                        currentPage={currentRagPage}
                        setCurrentPage={setCurrentRagPage}
                        recordsPerPage={recordsPerPage}
                    />
                    </Center>
                    <Drawer onClose={onDrawerClose} isOpen={isDrawerOpen} drawerValue={drawerValue} size='xl'>
                        <DrawerOverlay />
                        <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader borderBottomWidth='1px'>
                            도메인 정보
                        </DrawerHeader>

                        <DrawerBody>
                            <Stack spacing='24px'>
                            <Box>
                                <FormLabel htmlFor='domain'>도메인</FormLabel>
                                <Input
                                    id='domain_name'
                                    value={drawerValue.domain_name}
                                    bg='gray.100'
                                    readOnly={true}
                                />
                            </Box>

                            <Box>
                                <FormLabel htmlFor='desc'>이름</FormLabel>
                                <Textarea 
                                    id='desc'
                                    value={drawerValue.file_name}
                                    onChange={(e) => {
                                        setDrawerValue({...drawerValue, file_name: e.target.value});
                                    }}
                                    />
                            </Box>

                            <Box>
                                <FormLabel htmlFor='file-upload'>RAG 문서 등록</FormLabel>
                                <Input
                                    id='file-upload'
                                    type='file'
                                    p={1}
                                    accept="application/pdf"
                                    bg='gray.100'
                                    _hover={{ bg: 'gray.200' }}
                                    _focus={{ boxShadow: 'outline' }}
                                    disabled={true}
                                />
                                {drawerValue.file_name && (
                                    <Text mt={2} fontSize="sm" color="gray.500">
                                        선택된 파일: {drawerValue.file_name}
                                    </Text>
                                )}
                            </Box>
                            </Stack>
                        </DrawerBody>

                        <DrawerFooter borderTopWidth='1px'>
                            <Button variant='outline' mr={3} onClick={onDrawerClose}>
                            Cancel
                            </Button>
                            <Button colorScheme='blue' mr={3} onClick={handleUpdateRagOneRow}>Update</Button>
                            <Button colorScheme='red' onClick={()=>{
                                handleDeleteRagOneRow();
                            }}>Delete</Button>
                        </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                    </Flex>          
                </Container>
            </Flex>
            <Modal
                initialFocusRef={initialRef}
                isOpen={isModalOpen}
                onClose={onModalClose}
                size = 'xl'
            >
                <ModalOverlay/>
                <ModalContent>
                <ModalHeader>RAG 추가</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                    <FormLabel>도메인</FormLabel>
                        <Select
                            value={modalValue.domainId}
                            onChange={(e)=>{setModalValue({...modalValue, domainId: e.target.value, domainName: allDomainsData.result[e.target.value-1].domain_name})}}>                                
                                {allDomainsData.result.map((domainData)=>(
                                    <option
                                        key = {domainData.domain_id}
                                        value={domainData.domain_id}
                                    >
                                        {domainData.domain_name}
                                    </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl 
                        mt={4} 
                        ref={initialRef}>
                    <FormLabel>이름</FormLabel>


                    <Input 
                        placeholder='문서 첨부하시면 이름이 자동으로 설정됩니다.'
                        value={modalValue.fileName} 
                        onChange={(e)=>{setModalValue({...modalValue, fileName: e.target.value})}}
                    />
                    </FormControl>

                    <FormControl mt={4}>
                    <FormLabel>첨부문서</FormLabel>
                    <Input
                        type="file"
                        ref={fileInputRef}
                        accept=".pdf, .hwp, .docx, .pptx, .txt, .xlsx, .csv"
                        
                        hidden
                        onChange={(e)=>{
                            setModalValue({...modalValue, fileName: e.target.files[0].name, file: e.target.files[0]} )
                        }}
                    />
                    <Button onClick={modalFileInputButtonClick} colorScheme="teal">
                        {modalValue.file.name ? modalValue.file.name : '파일 선택' || "파일 선택"}
                    </Button>
                    </FormControl>

                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={()=>{
                        try {
                            setActivedDomainIndex(0);
                            setInputKeyword("");
                        } finally {
                            handleGetRags();
                        }
                        handleCreateRagOneRow();
                        }}>
                    Save
                    </Button>
                    {/* <Button 
                        onClick={() => {
                            setModalValue({
                                domainId: '1', domainName: '복리후생', fileName: '', file: {name: "파일 선택"}
                            });
                        }}
                        mr={3}
                        colorScheme="orange">
                    Initialize</Button> */}
                    <Button
                    onClick={()=>{
                        onModalClose();
                        setModalValue({
                            domainId: '1', domainName: '복리후생', fileName: '', file: {name: "파일 선택"}
                        });
                        try {
                            setActivedDomainIndex(0);
                            setInputKeyword("");
                        } finally {
                            handleGetRags();
                        }
                    }}>
                        Cancel
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    )

}

export default RagPage;