import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  InputGroup,
  InputRightElement,
  IconButton,
  InputProps,
  useDisclosure,
  useMergeRefs,
  useToast,
  localStorageManager,
} from '@chakra-ui/react'

import { ArrowLeftIcon } from '@chakra-ui/icons'

import { useRef, useState, useCallback } from 'react'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { postSignIn } from '../Api';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from '../components/uiLockingComponent';
import { calculateAccessTokenExpireTime, calculateRefreshTokenExpireTime, calculateSessionExpireTime } from '../components/timeCalculatingComponent';

const SignInPage = ({ isSignedIn, setIsSignedIn}) => {

  const toast = useToast();  
  const navigate = useNavigate();
  const [isUILocked, setUILocked] = useState(false);

  const { isOpen, onToggle } = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)

  const [userName, setUserName] = useState('');
  const [passWord, setPassWord] = useState('');

  const onClickReveal = () => {
      onToggle()
      if (inputRef.current) {
          inputRef.current.focus({ preventScroll: true })
      }
  }

  const handlePostSignIn = useCallback(async () => {

      const username = userName;
      const password = passWord;
      setUILocked(true);
      try {
          const data = await postSignIn(username, password);

          if (data['result'] === 1) {
              toast({
                  title: 'Sign In Success',
                  description: '로그인 성공',
                  status: 'success',
                  isClosable: true,
                  position: 'top'
              })
              setUILocked(false);
              setIsSignedIn(true);

              localStorage.setItem('session-exp', calculateSessionExpireTime());
              localStorage.setItem('access-token-exp', calculateAccessTokenExpireTime());
              localStorage.setItem('refresh-token-exp', calculateRefreshTokenExpireTime());
              
              navigate('/rag-page');
              return;
          } else {
              toast({
                  title: 'Sign In Failed',
                  description: '로그인 실패',
                  status: 'error',
                  isClosable: true,
                  position: 'top'
              })
              return;
          }
          
      } catch (error) {
          toast({
              title: 'Fetch Failed',
              description: '로그인 실패, 관리자에게 문의하세요',
              status: 'error',
              isClosable: true,
              position: 'top'
          });
      } finally{
          setUILocked(false);
      }
  });

  return(
      <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <LoadingOverlay isLoading={isUILocked} />
      <IconButton
        borderWidth='1px' shadow='md' size = 'lg'
        onClick={() => { 
            navigate('/');
        }}
        icon={<ArrowLeftIcon />}
        colorScheme='teal'
        />
      <Stack spacing="8">
          <Stack spacing="6">
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                  <Heading>AMP Service</Heading>
              </Stack>
          </Stack>
          <Box
              py={{ base: '0', sm: '8' }}
              px={{ base: '4', sm: '10' }}
              bg={{ base: 'transparent', sm: 'bg.surface' }}
              boxShadow={{ base: 'none', sm: 'md' }}
              borderRadius={{ base: 'none', sm: 'xl' }}
          >
              <Stack spacing="6">
                  <Stack spacing="5">
                      <FormControl>
                          <FormLabel htmlFor="username">User Name</FormLabel>
                          <Input id="username" type="username" onChange={(e) => setUserName(e.target.value)}/>
                      </FormControl>
                      <FormControl>
                          <FormLabel htmlFor="password">Password</FormLabel>
                          <InputGroup>
                              <InputRightElement>
                              <IconButton
                                  variant="text"
                                  aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                                  icon={isOpen ? <HiEyeOff /> : <HiEye />}
                                  onClick={onClickReveal}
                              />
                              </InputRightElement>
                              <Input
                              id="password"
                              name="password"
                              type={isOpen ? 'text' : 'password'}
                              autoComplete="current-password"
                              required
                              onChange={(e) => setPassWord(e.target.value)}
                              />
                          </InputGroup>
                      </FormControl>
                  </Stack>
                  <Stack spacing="6">
                      <Button 
                      onClick={handlePostSignIn}
                      colorScheme='teal'
                      >Sign in</Button>
                  </Stack>
              </Stack>
          </Box>
      </Stack>
      </Container>
  )
}

export default SignInPage;
